/* Button used to open the chat form - fixed at the bottom of the page */

.app {
    position: absolute;
    top:0;
    bottom:0;
    left: 0;
    right: 0;
}
.open-button-1 {
    background-color: #555;
    color: white;
    padding: 16px 20px;
    border: none;
    cursor: pointer;
    opacity: 0.8;
    position: fixed;
    bottom: 23px;
    left: 228px;
    width: 280px;
}

.open-button {
    background-color: #555;
    color: white;
    padding: 16px 20px;
    border: none;
    cursor: pointer;
    opacity: 0.8;
    position: fixed;
    bottom: 23px;
    right: 28px;
    width: 280px;
}

/* The popup chat - hidden by default */

.chat-popup {
    display: none;
    position: fixed;
    bottom: 0;
    right: 15px;
    border: 3px solid #f1f1f1;
    z-index: 9;
}

.chat-popup-1 {
    display: none;
    position: fixed;
    bottom: 0;
    left: 228px;
    border: 3px solid #f1f1f1;
    z-index: 9;
}

/* Add styles to the form container */

.form-container {
    max-width: 400px;
    padding: 10px;
    background-color: white;
}

.form-container-1 {
    max-width: 400px;
    padding: 10px;
    background-color: white;
}

/* Full-width textarea */

.form-container .message-list {
    width: 300px;
    padding: 20px;
    margin: 5px 0 22px 0;
    border: none;
    background: #f1f1f1;
    resize: none;
    min-height: 200px;
    height: 100px;
    overflow: auto;
}

.form-container-1 .message-list {
    width: 300px;
    padding: 20px;
    margin: 5px 0 22px 0;
    border: none;
    background: #f1f1f1;
    resize: none;
    min-height: 200px;
    height: 100px;
    overflow: auto;
}

/* When the textarea gets focus, do something */

/* Set a style for the submit/send button */

.form-container .btn {
    background-color: #4CAF50;
    color: white;
    padding: 10px 15px;
    border: none;
    cursor: pointer;
    width: 100%;
    margin-bottom: 10px;
    opacity: 0.8;
}

/* Add a red background color to the cancel button */

.form-container .cancel {
    background-color: red;
}

.form-container-1 .cancel {
    background-color: red;
}

/* Add some hover effects to buttons */

.form-container .btn:hover, .open-button:hover {
    opacity: 1;
}

.form-container-1 .btn:hover, .open-button:hover {
    opacity: 1;
}

/* Moderator Publish Video  */

.div-section {
    margin-bottom: 8px;
}

.screenshare {
    position: absolute !important;
}

.topWrapper {
    padding: 0px !important;
}

.cameraChange {
    width: 150px;
    height: 150px;
    object-fit: contain;
    margin: 10px auto;
    display: block;
}

.form-element {
    margin: 10px auto;
}

.vertical {
    display: inline-block;
    width: 20%;
    height: 40px;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.vertical {
    box-shadow: inset 0px 4px 6px #ccc;
}

.progressWrapper {
    width: 20px;
    height: 260px;
    margin: 25px auto;
    border: 2px solid #5e6d7a;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
}

.progressBar {
    width: 100%;
    height: 50%;
    background: #5e6d7a;
    position: absolute;
    bottom: 0px;
    left: 0px;
}

.Blink {
    animation: blinker .8s cubic-bezier(.5, 0, 1, 1) infinite alternate;
}

@keyframes blinker {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.full-modal-dialog {
    height: 100% !important;
    min-height: 100% !important;
    max-height: 100% !important;
    padding: 0 !important;
}

.full-modal-dialog>.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #d1d0ce;
    background-clip: padding-box;
    border: none;
    border-radius: 0rem;
    outline: 0;
    height: 100% !important;
}

.full-modal-dialog>.modal-content>.modal-header {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    padding: 1rem 1rem 1rem 1rem;
    border-bottom: 1px solid #a6a6a6;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.full-modal-dialog>.modal-content>.modal-header>.close {
    float: right;
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 1;
    color: #010103;
    text-shadow: none;
    font-weight: none;
    background-color: #a6a6a6 !important;
    border: none;
    opacity: 1;
}

.full-modal-dialog>.modal-content>.modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem;
    border-top: 2px solid #a6a6a6;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.bg-color--grey-65op {
    background-color: #a6a6a6;
}

.bg-color--blue-variant {
    background-color: #377fd3;
}

.border-radius--d6rem {
    border-radius: .6rem;
}

.custom-form-control-message {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #010103;
    background-color: #a6a6a6;
    background-clip: padding-box;
    border: none;
    border-top-left-radius: 0.6rem;
    border-bottom-left-radius: 0.6rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-form-control-message:focus {
    color: #010103;
    background-color: #a6a6a6;
    border-color: #80bdff;
    outline: 0;
    box-shadow: none;
}

.custom-input-group {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
}

.custom-input-group-append {
    display: -ms-flexbox;
    display: flex;
}

.custom-input-group>.custom-form-control-message {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;
}

.custom-input-group-text {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    font-size: .6rem;
    font-weight: 400;
    line-height: 1.5;
    color: #010103;
    text-align: center;
    white-space: nowrap;
    background-color: #a6a6a6;
    border-left: 1px solid #a6a6a6;
    border-top-right-radius: 0.6rem;
    border-bottom-right-radius: 0.6rem;
}

.bubble-left, .bubble-right {
    line-height: 100%;
    display: block;
    position: relative;
    padding: .25em .5em;
    border: none;
    clear: both;
    max-width: 85%;
    word-break: break-word;
}

.bubble-left {
    float: left;
    margin-right: 10%;
}

.bubble-right {
    float: right;
    margin-left: 10%
}

.border--1rem {}

/* html, body {
    height: 100%;
    width: 100%;
    position: absolute;
}

* {
    margin: 0;
    user-select: none;
} */

.h--85 {
    height: 85%;
}

.w--90 {
    width: 90%;
}

.bg-color--purple {
    background-color: #5C217C !important;
}

.bg-color--white-opacity-d30 {
    background-color: #ffffff30;
}

.bg-color--white-opacity-d40 {
    background-color: #ffffff40;
}

.bg-color--light-golden {
    background-color: #d89e39;
}

.font-family--Gotham-Light {
    font-family: "Gotham-Light";
}

.font-family--Gotham-Bold {
    font-family: "Gotham-Bold";
}

.font-family--Gotham-Medium {
    font-family: "Gotham-Medium";
}

.font-family--Gotham-Book {
    font-family: "Gotham-Book";
}

.font-color--white {
    color: #ffffff !important;
}

.line-height--2d2 {
    line-height: 2.2rem;
}

.border-bottom--light-white {
    border-bottom: 1px solid #ffffff59;
}

.btn--purple {
    display: inline-block;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #5C217C;
    border: none;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
}

.btn--purple:hover {
    color: #ffffff;
    text-decoration: none;
}

.btn--purple.disabled, .btn--purple:disabled {
    opacity: 0.65;
}

.form-control--input-text {
    display: block;
    width: 100%;
    /* height: calc(1.5em + 0.75rem + 2px); */
    padding: 0.377rem 0.75rem;
    font-size: 0.975rem;
    font-weight: 400;
    line-height: 1.5;
    color: #010103;
    font-family: "Gotham-Light";
    background-color: #fff;
    background-clip: padding-box;
    border: none;
    border-radius: 0;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control--input-text:focus {
    color: #010103;
    background-color: #fff;
    outline: 0;
    box-shadow: none;
}

.form-control--input-text::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1;
}

.form-control--input-text::-moz-placeholder {
    color: #6c757d;
    opacity: 1;
}

.form-control--input-text:-ms-input-placeholder {
    color: #6c757d;
    opacity: 1;
}

.form-control--input-text::-ms-input-placeholder {
    color: #6c757d;
    opacity: 1;
}

.form-control--input-text::placeholder {
    color: #6c757d;
    opacity: 1;
}

.input-group--input-text {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
}

.input-group-append--input-text {
    display: -ms-flexbox;
    display: flex;
}

.input-group--input-text>.form-control--input-text {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;
}

.input-group-text--input {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    font-size: .975rem;
    font-weight: 400;
    line-height: 1.5;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #5C217C;
    border-radius: 0;
}

.text-decoration--underline {
    text-decoration: underline !important;
}

.font-size-d6 {
    font-size: .6rem !important;
}

.font--size-d7 {
    font-size: .7rem !important;
}

.font--size-d5 {
    font-size: .5rem;
}

.font--size-d8 {
    font-size: .8rem;
}

.font-size--d89 {
    font-size: .89rem !important;
}

.font-size--2d4 {
    font-size: 2rem;
}

.font-size--1rem {
    font-size: 1rem
}

.font-size--1d25 {
    font-size: 1.25rem;
}

.font-size--1d5 {
    font-size: 1.25rem;
}

.letter-spacing--1px {
    letter-spacing: 1px;
}

.letter-spacing--4px {
    letter-spacing: 4px;
}

.letter-spacing--d3 {
    letter-spacing: .3px;
}

.bg-color--white {
    background-color: #ffffff !important;
}

.rounded--d6 {
    border-radius: 0.6rem;
}

.rounded--d8 {
    border-radius: 0.8rem;
}

.letter-spacing--1d2px {
    letter-spacing: 1.2px;
}

.text-color--purple {
    color: #5C217C !important;
}

.text-color--success-light {
    color: #58bc4c
}

.bg-color--light-red {
    background-color: #e03d58;
}

.list-group--chat>.list-group-item {
    background-color: #ffffff50;
    border: 1px solid #8e6399;
    color: #f7f5f8;
}

.bg-color--light-purple {
    background-color: #845d9e !important;
}

.custome-header>.navbar {
    justify-content: start;
}

.vertical-align--sub {
    vertical-align: sub;
}

.font-size--1d3 {
    font-size: 1.3rem;
}

.border-bottom--white-opacity-d90 {
    border-bottom: 1px;
    border-bottom-style: solid;
    border-bottom-color: #ffffff90;
}

.purple-form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #ffffff;
    background-color: #845d9e;
    background-clip: padding-box;
    border: none;
    border-top-left-radius: 0.6rem;
    border-bottom-left-radius: 0.6rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.purple-form-control:focus {
    color: #ffffff;
    background-color: #845d9e;
    outline: 0;
    box-shadow: none;
}

.purple-input-group {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
}

.purple-input-group-append {
    display: -ms-flexbox;
    display: flex;
}

.purple-input-group>.purple-form-control {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;
}

.purple-input-group-text {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    font-size: .6rem;
    font-weight: 400;
    line-height: 1.5;
    color: #ffffff;
    text-align: center;
    white-space: nowrap;
    background-color: #845d9e;
    border-radius: 2px;
    border: none;
}

.purple-form-control::-webkit-input-placeholder {
    color: #ffffff90;
    opacity: 1;
}

.purple-form-control::-moz-placeholder {
    color: #ffffff90;
    opacity: 1;
}

.purple-form-control:-ms-input-placeholder {
    color: #ffffff90;
    opacity: 1;
}

.purple-form-control::-ms-input-placeholder {
    color: #ffffff90;
    opacity: 1;
}

.form-control::placeholder {
    color: #ffffff90;
    opacity: 1;
}

.chat-scroller::-webkit-scrollbar, .chat-scroller::-webkit-scrollbar-thumb, .chat-scroller::-webkit-scrollbar-track {
    width: 8px;
    border: none;
    background: transparent;
}

.chat-scroller::-webkit-scrollbar-button, .chat-scroller::-webkit-scrollbar-track-piece, .chat-scroller::-webkit-scrollbar-corner, .chat-scroller::-webkit-resizer {
    display: none;
}

.chat-scroller::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: #91588f;
}

.chat-scroller::-webkit-scrollbar-track {
    background-image: linear-gradient(to right, #5C217C59, #5C217C59);
    background-repeat: repeat-y;
    background-position: center;
    background-size: 1.8px;
}

.stalls-scroller::-webkit-scrollbar, .stalls-scroller::-webkit-scrollbar-thumb, .stalls-scroller::-webkit-scrollbar-track {
    width: 8px;
    border: none;
    background: transparent;
}

.stalls-scroller::-webkit-scrollbar-button, .stalls-scroller::-webkit-scrollbar-track-piece, .stalls-scroller::-webkit-scrollbar-corner, .stalls-scroller::-webkit-resizer {
    display: none;
}

.stalls-scroller::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: #2e353a;
}

.stalls-scroller::-webkit-scrollbar-track {
    background-image: linear-gradient(to right, #2e353a59, #2e353a59);
    background-repeat: repeat-y;
    background-position: center;
    background-size: 1.8px;
}

.font--size-1rem {
    font-size: 1rem !important;
}

.font--size-1d5rem {
    font-size: 1.5rem !important;
}

.padding-left--2rem {
    padding-left: 2rem;
}

.padding-right--2rem {
    padding-right: 2rem;
}

.padding-x--2rem {
    padding-left: 2rem;
    padding-right: 2rem;
}

.badge--purple {
    border-top-right-radius: 5rem;
    background-color: #5C217C;
    border-top-left-radius: 5rem;
    border-bottom-right-radius: 5rem;
    display: inline-flex;
    height: 1.5rem;
    width: 1.5rem;
    justify-content: center;
    padding: 4px;
    align-items: center;
    float: right;
}

.session-sidebar {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1000000;
    top: 0;
    right: 0;
    background-color: #fff;
    overflow-x: hidden;
    transition: 0.5s;
}

#main-content {
    transition: margin-right .5s;
}

.bg-color--light-silver {
    background-color: #e5e5e5;
}

.text-color--white {
    color: white;
}

.border-bl-radius--1d2 {
    border-bottom-left-radius: 1.2rem;
}

.border-tl-radius--1d2 {
    border-top-left-radius: 1.2rem;
}

.bg-color--grey-variant-1 {
    background-color: #e6e6e6
}

.bg-color--grey-variant-2 {
    background-color: #f2f2f2
}

.bg-color--white-variant-1 {
    background-color: #fafafa;
}

.height--47vh {
    height: 47vh;
}

.height--73vh {
    height: 73vh;
}

.overflowY--auto {
    overflow-y: auto;
}

.overflowX--auto {
    overflow-x: auto;
}

.height--25vh {
    height: 25vh;
}

.h--70vh {
    height: 70vh;
}

.h--8vh {
    height: 8vh;
}

.h--100vh {
    height: 100vh;
}

.openbtn {
    font-size: 20px;
    cursor: pointer;
    background-color: #111;
    color: white;
    padding: 10px 15px;
    border: none;
    top: 50%;
    right: 0%;
}

.openbtn:hover {
    background-color: #444;
}

.right--1per {
    right: 1%;
}

.top--50per {
    top: 50%
}

#openSideBar {
    transition: margin-right .5s;
}

.top--45per {
    top: 45%;
}

.sidebar-arrow {
    background-color: #00000045 !important;
    height: 15vh !important;
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
    padding: .4rem !important;
    display: flex !important;
    align-items: center !important;
    color: #fff !important;
}

input {
    -webkit-user-select: text !important;
    -moz-user-select: text !important;
    -ms-user-select: text !important;
    user-select: text !important;
}

.justify-content--space-evenly {
    justify-content: space-evenly;
}

.pulse-animation {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }
    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }
    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

.modal--rounded-0>.modal-content {
    border-radius: 0rem;
}

.modal--scroller>.modal-content>.modal-body::-webkit-scrollbar {
    width: 10px;
}

.modal--scroller>.modal-content>.modal-body::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.modal--scroller>.modal-content>.modal-body::-webkit-scrollbar-thumb {
    background: #888;
}

.modal--scroller>.modal-content>.modal-body::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.black-sleek-scroller::-webkit-scrollbar {
    width: 5px;
    height: 8px;
}

.black-sleek-scroller::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.black-sleek-scroller::-webkit-scrollbar-thumb {
    background: #888;
}

.black-sleek-scroller::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.cursor--not-allowed {
    cursor: not-allowed;
}

.bg-color--purple-opacity-59 {
    background-color: #5C217C59 !important;
}

.form-control--no-box-shadow:focus {
    box-shadow: none !important;
}

.form-control--no-border:focus {
    border: none !important;
}

.w--90 {
    width: 90%;
}

.w--70 {
    width: 70%;
}

.w--80 {
    width: 80%;
}

.wait-screen {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #c8c8c8;
    font-family: "Gotham-Bold";
    top: 0px;
    left: 0px;
    font-size: 1.2rem;
    z-index: 2000
}

.border-right--width {
    border-right-width: 1.5px !important;
}

.border-right--color-white {
    border-right-color: #fff !important;
}

.loader {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #c8c8c8;
    top: 0px;
    left: 0px;
    z-index: 100000;
}

.height-6vh {
    height: 6vh;
}

.height-30vh {
    height: 30vh;
}

.counter .goUp{
    display: inline-flex;
    opacity: 0;
    transform: translate3d(0, -20px, 0);
    transition: 0.2s ease-in-out;
}
.counter .waitDown{
    display: inline-flex;
    opacity: 0;
    transform: translate3d(0, 20px, 0);
}
.counter .initial{
    display: inline-flex;
    opacity: 1;
    transform: translate3d(0, 0px, 0);
    transition: 0.2s ease-in-out;
}

.font--size-1d4 {
    font-size: 1.4rem;
}

.height-d3rem {
    height: .3rem !important
}

.right--d2per {
    right: .2%;
}

.bg-danger--opacity26 {
    background-color: #ff000026 
}